.c-buttonArea {
  width: 100%;
  text-align: center;
}

.c-button {
  position: relative;
  display: inline-block;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 1.28em 20px;
  text-decoration: none;
  letter-spacing: 0.05em;
  border: 0;
  appearance: none;
  color: $color-text-white;
  transition: all .4s;
  z-index: 0;
  @include mq(sm) {
    padding: 1em 20px;
  }
  &::before {
    @include make-gradation;
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -1;
    transition: all .4s;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &::after {
    background-color: $color-primary;
    // @include make-gradation2;
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:focus {
    outline:0;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover::before {
    opacity: 0;
  }
}

.c-button--arrow {
  span::after {
    content: "";
    display: inline-block;
    width: 30px;
    height: 10px;
    background-image: url(../images/arrow.svg);
    margin-left: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: super;
  }
}

.c-moreButton {
  text-decoration: none;
  display: inline-block;
  text-align: center;
  font-weight: 300;
  letter-spacing: 0.5em;
  font-size: 12px;
  height: 48px;
  line-height: 48px;
  vertical-align: middle;
  border-bottom: solid 2px $color-text-primary;

  &:hover {
    cursor: pointer;
  }

  &:active, &:visited {
    color: $color-text-primary
  }

  &--white {
    color: $color-text-white;
    border-bottom: solid 1px $color-text-white;
    border-top: solid 1px $color-text-white;
    padding: 0 48px; 
    &:active, &:visited {
      color: $color-text-white;
    }
  }
}
