
.c-itemSideNavWrapper {
  width: 280px;

  @include mq(md) {
    width: 100%;
    padding: 0 20px;
  }
}

.c-itemSideNavList {
  margin-bottom: 64px;

  li a {
    font-size: 15px;
    font-weight: 100;
    line-height: 2.2;
    text-decoration: none;
    color: $color-text-primary;
    letter-spacing: 0;
    white-space: nowrap;
  }
}

.c-itemList {
  display: flex;
  flex-wrap: wrap;

  @include mq(sm) {
    padding: 0 20px;
    margin-top: 40px;
  }

  li {
    width: calc(33% - 36px);
    max-width: 280px;
    margin: 0 18px;
    position: relative;
    margin-bottom: 60px;

    @include mq(sm) { 
      width: calc(33% - 18px);
      margin: 0 8px 45px;
    }

    @include mq-min(xl) {
      width: calc(25% - 36px);
    }
  }

  &__imgWrapper {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    margin-bottom: 20px;
  }

  li img {
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all .3s;
  }

  li a {
    text-decoration: none;
    text-align: center;
  }

  li a:hover img {
    transform: scale(1.1);
  }

  &__text {
    font-size: 12px;
    margin-bottom: 1em;
    color: $color-text-primary;
    text-decoration: none;
  }

  &__price {
    font-size: 12px;
    color: $color-text-primary;
    text-decoration: none;
  }

}