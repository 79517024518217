.u-sp-hidden {
  @include mq(sm) {
    display: none;
  }
}

.u-sp-show {
  display: none;
  @include mq(sm) {
    display: block;
  }
}

.u-nmr-sp-20 {
  @include mq(sm) {
    margin-right: -20px;
  }
}

.u-vh-100 {
  height: calc(100vh - 400px);
}

.u-center {
  text-align: center;
}

.u-link {
  color: $color-text-primary;
  transition: $transition;

  &:hover {
    opacity: 0.6;
  }
}

.u-color-red {
  color: red;
}