.p-contact {
  background-color: $color-bg-light-secondary;
  padding-bottom: 20px;
  .c-inner {
    text-align: center;
    @include mq(sm) {
      text-align: left;
    }
  }
  .c-subText {
    text-align: center;
    @include mq(sm) {
      text-align: left;
    }
  }
}

.p-contactbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 100%;
  background-color: #000;
  color: white;
  padding: 20px;
  position: fixed;
  bottom: 0;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all .4s;

  &.is-visible {
    opacity: 1;
    visibility: visible;
  }

  @include mq(sm) {
    flex-direction: column;
    height: auto;
    padding: 20px 10px;
  }
  
  > p {
    @include make-paragraph;
    color: white;
    text-align: center;
    margin-right: 60px;
    @include mq(sm) {
      margin-right: 0px;
      font-size: 1.4rem;
      margin-bottom: 10px;
      line-height: 1.3;
    }
  }

}