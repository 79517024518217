.c-table {
  border-collapse:collapse;
  margin:0 auto;
  width: 100%;

  td,th{
    padding:10px;
  }

  td, th {
    text-align: center;
  }

  td:first-child, th:first-child {
    text-align: left;
  }

  tr th:first-child, tr td:first-child {
    padding: 0 42px;
  }

  tr:nth-child(even){
    background:#eae5e3;
  }
}
