.p-system {
  background-color: $color-bg-light;

  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 30px;
    @include mq(sm) {
      flex-direction: column;
       margin-bottom: 40px;
    }

    > li {
      width: calc(33.333% - 28px);
      position: relative;
      @include mq(sm) {
        width: 100%;
        margin-bottom: 60px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child::after {
        display: none;
      }
      &::after {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(50%, -50%);
        content: "";
        display: inline-block;
        width: 30px;
        height: 20px;
        background-image: url(../images/sec04/arrow_black.svg);
        background-size: contain;
        background-repeat: no-repeat;
        @include mq(sm) {
          top: 100%;
          left: 50%;
          transform: translate(-50%, 25px) rotate(90deg) ;
        }
      }
    }

    img {
      width: 100%;
      margin-bottom: 10px;
    }

    &__title {
      background-color: $color-primary;
      color: $color-text-white;
      font-weight: bold;
      text-align: center;
      font-size: 18px;
      line-height: 2;
      width: 100%;
      display: block;
    }
  }

  &__list2 {
    display: flex;
    justify-content: space-between;
    background-color: $color-bg-lightest;
    border: solid 3px $color-primary;
    border-radius: 5px;
    @include mq(md) {
      flex-direction: column;
    }
    > li {
      display: flex;
      align-items: center;
      width: calc(33.333% - 0px);
      position: relative;
      padding: 20px 40px 20px 20px;
      @include mq(md) {
        width: 100%;
        padding: 20px 28px;
      }
    }

    &__imgWrapper {
      display: inline-block;
      margin-right: 10px;
      @include mq(md) {
        width: 30%;
        text-align: center;
        > img {
          margin: 0 auto;
        }
      }
    }

    &__content {
       display: inline-block;
       font-weight: bold;

      strong {
        border-bottom: solid 2px $color-text-primary;
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-size: 1.8rem;
        display: block;
        font-weight: bold;
        &::before {
          content: "";
          display: inline-block;
          width: 21px;
          height: 18px;
          margin-right: 10px;
          background-image: url(../images/sec04/caution.svg);
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      p {
        font-weight: bold;
        text-align: center;
      }
    }
  }
}