.p-interest {
  background: url(../images/bg.png), linear-gradient(0deg, rgba(0,128,237,1) 0%, rgba(34,214,180,1) 100%);
  background: url(../images/bg.png), -moz-linear-gradient(0deg, rgba(0,128,237,1) 0%, rgba(34,214,180,1) 100%);
  background: url(../images/bg.png), -webkit-linear-gradient(0deg, rgba(0,128,237,1) 0%, rgba(34,214,180,1) 100%);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  padding: 130px 0 60px;
  @include mq(md) {
    padding: 66px 20px 40px;
  }

  &__content {
    background: $color-bg-lightest;
    padding: 40px 55px;
    border-radius: 5px;
    @include mq(md) {
      padding: 40px 0px;
      margin-top: 80px;
    }
    @include mq(sm) {
      padding: 20px 33px;
      margin-top: 0px;
    }

    > .c-heading {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      @include mq(sm) {
        font-size: 1.8rem;
        margin-bottom: 40px;
      }
    }
  }

  &__list {
    display: flex;

    @include mq(sm) {
      flex-direction: column;
    }

    > li {
      margin: 30px 20px 0;
      flex: 1;
      @include mq(md) {
        position: relative;
        width: 33%;
      }
      @include mq(sm) {
        margin: 0 0 40px;
        width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }

      > div {
        max-width: 492px;
        position: relative;
        @include mq(md) {
          width: 100%;
          max-width: 100%;
        }

        > img {
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }

  &__list__title {
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 1.8rem;
    color: $color-text-white;
    background-color: $color-primary;
    display: block;
    padding: 10px;
    text-align: center;
    font-weight: bold;
  }

  &__list__text {
    line-height: 1.6;
  }
  
}