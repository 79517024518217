.p-flow {
  &__list {
    width: 100%;
    counter-reset: number 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    @include mq(md) {
      flex-direction: column;
    }

    > li {
      margin-right: 50px;
      padding: 20px 20px 40px 20px;
      position: relative;
      @include mq(md) {
        margin-right: 0;
        padding: 20px;
        display: flex;
        align-items: center;
        margin-bottom: 42px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    > li:nth-child(odd) {
      background-color: $color-bg-light-secondary;
    }

    > li:nth-child(even) {
      background-color: $color-primary;
      color: white;
    }

    > li::before {
      margin-bottom: 10px;
      display: block;
      width: 100%;
      text-align: center;
      font-size: 2.5rem;
      font-weight: bold;
      counter-increment: number 1;
      content: "0" counter(number);
      @include mq(md) {
        width: auto;
        margin-right: 10px;
      }
    }

    > li:last-child::after {
      display: none;
    }
      
    > li::after {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate(80%, -50%);
      content: "";
      display: inline-block;
      width: 20px;
      height: 30px;
      background-image: url(../images/sec04/arrow_black.svg);
      background-size: contain;
      background-repeat: no-repeat;
      @include mq(md) {
        top: 100%;
        left: 50%;
        transform: rotate(90deg) translate(50%, 0%);
      }
    }

    > li img {
      margin-bottom: 20px;
      @include mq(md) {
        margin-right: 12px;
        margin-bottom: 0;
      }
    }

    > li p {
      font-weight: bold;
    }
  }

  figcaption {
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq(md) {
      display: block;
    }

    p {
      line-height: 30px;
      vertical-align: middle;
      margin-bottom: 10px;
    }

    p:nth-child(odd) {
      margin-right: 20px;
    }

    p::before {
      width: 30px;
      height: 30px;
      border-radius: 5px;
      content: "";
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }

    p:nth-child(odd)::before {
      background-color: $color-primary;
    }

    p:nth-child(even)::before {
      background-color: $color-bg-light-secondary;
    }
  }
}