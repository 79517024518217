.p-scene {

  &__list {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    @include mq(md) {
      flex-wrap: wrap;
    }
    @include mq(sm) {
      flex-direction: column;
    }

    > li {
      width: calc(25% - 20px);
      @include mq(md) {
        width: calc(50% - 20px);
        margin-bottom: 40px;
      }
      @include mq(sm) {
        width: 100%;
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__list__imgWrapper {
    width: 100%;
    >img {
      width: 100%;
    }
  }

  &__list__title {
    @include make-paragraph;
    text-align: center;
    margin: 20px 0;
    @include mq(md) {
      letter-spacing: 0.02em;
    }

    > br {
      @include mq(md) {
        display: none;
      }
    }
  }

  &__list__text {

  }
}

