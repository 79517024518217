.p-hero {
  background-image: url(../images/main/main_bg.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;

  @include mq(sm) {
    margin-bottom: 0;
  }

  &__content {
    text-align: center;
    margin: 0 auto;
    color: $color-text-white;
    padding-top: 24vh;
    @include mq(sm) {
      padding-top: 40%;
    }
  }

  &__caption {
    font-size: 3rem;
    line-height: 2;
    font-weight: bold;
    margin-bottom: 2.8rem;
    @include mq(sm) {
      font-size: 1.7rem;
    }
  }

  &__title > img {
    height: 72px;
    @include mq(sm) {
      height: auto;
      width: 30rem;
    }
  }

  &__title {
    margin-bottom: 50px;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    z-index: 1;

    > span {
      display: inline-block;
      font-size: 2rem;
      font-weight: bold;
      background: $color-bg-lightest;
      color: $color-text-primary;
      padding: 18px;
      margin-bottom: 12px;
      @include mq(sm) {
        font-size: 1rem;
        padding: 5px 10px;
      }
    }
  }
}
