@mixin button {
  appearance: none;
  border: solid 1px $color-border-secondary;
  background-color: white;
  text-decoration: none;
  color: $color-text-primary;
  text-align: center;
  padding: 14px 18px;
  transition: $transition;

  &:hover {
    opacity: 0.7;
  }
}

.c-form {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;

  @include mq(md) {
    width: 100%;
    margin-bottom: 2em;
  }

  label {
    width: 144px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__text {
    background-color: white;
    height: 40px;
    line-height: 40px;
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    border-radius: 4px;
    width: 100%;
    padding: 2px 10px;
    box-sizing: border-box;

  }

  &__textarea {
    background-color: white;
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    border-radius: 4px;
    width: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
  }

  &__item {
    display: flex;
    margin-bottom: 1em;
    flex-direction: column;
    text-align: left;

    @include mq(sm) {
      flex-direction: column;
    }

    .c-form__input {
      margin-right: 8px;
      @include mq(sm) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }

  &__select {
    appearance: none;
    border: solid 1px $color-border-secondary;
    background-color: white;
    padding: 14px 18px;
    color: $color-text-primary;
    width: 100%;
    max-width: 320px;
    height: 3em;
    line-height: 3em;
  }

  &__input {
    appearance: none;
    border: solid 1px $color-border-secondary;
    padding: 14px 18px;
    color: $color-text-primary;
    flex: 1;
    @include mq(sm) {
      width: 100%;
      box-sizing: border-box;
    }
  }

  &__addButton {
    appearance: none;
    background: $color-bg-dark;
    border: solid 1px $color-bg-dark;
    color: white;
    transition: $transition;
    padding: 10px 25px;
    white-space: nowrap;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    @include mq(sm) {
      width: 100%;
      box-sizing: border-box;
      padding: 12px 25px;
    }

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  &__submitWrapper {
    justify-content: center;

    > .c-button {
      width: 200px;
      margin: 0 auto;
      border-radius: 4px;
      margin-top: 40px;
      margin-bottom: 60px;
    }
  }

}