.c-heading {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.8;
  display: inline-block;
  letter-spacing: 0.1em;
  @include mq(sm) {
    font-size: 2rem;
    line-height: 3;
  }
  strong {
    font-size: 4.8rem;
    margin-right: 0.5em;
    letter-spacing: 0.05em;
    @include mq(sm) {
      font-size: 4.4rem;
    }
    @include mq(xs) {
      margin-right: 0.1em;
    }
  }
}

.c-heading--condensed {
  @include mq(sm) {
    letter-spacing: 0;
  }
}

.c-heading--sm {
  font-size: 2rem;
  padding: 0.8em 20px;
  line-height: 2.4;
  margin-bottom: 10px!important;
  @include mq(sm) {
    font-size: 1.9rem;
    padding-left: 16px;
  }
}

.c-heading--colored {
  @include make-gradation;
  color: $color-text-white;
  padding: 0em 1em;
  margin-bottom: 40px;
  line-height: 2.8;
  @include mq(md) {
    margin-bottom: 30px;
  }
  @include mq(xs) {
    padding: 0em 0.5em;
  }
}

br + .c-heading {
  line-height: 2;
  @include mq(sm) {
    font-size: 2.9rem;
    line-height: 2;
    padding-left: 16px;
  }
  @include mq(sm) {
    width: 100%;
  }
}

.c-heading--white {
  background-color: $color-bg-lightest;
  padding: 0em 1em;
  margin-bottom: 40px;
}

.c-subText {
  @include make-paragraph;
  margin-bottom: 40px;
}

.c-subHeading {
  font-size: 15px;
  font-weight: 300;
  line-height: 2.2;
  letter-spacing: 0;
  margin-bottom: 18px;
  @include mq(md) {
    margin-bottom: 0em;
  }
}

.c-smallHeading {
  font-size: 14px;
  line-height: 2;
  margin-bottom: 2em;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "Yu Gothic", sans-serif;

}

.c-textDefault {
  font-size: 14px;
  line-height: 2;
  font-weight: 500;
  font-family: "游ゴシック体", YuGothic, "Yu Gothic", sans-serif;

}

.c-verticalHeading {
  writing-mode: vertical-rl;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.46;
  margin-left: 1em;
  letter-spacing: 0.4em;
  @include mq(sm) {
    writing-mode: unset;
    margin: 2em 0 0.5em;
    font-size: 20px;
    letter-spacing: .2em;
    line-height: 1.9;
  }
}

.c-sectionHeading {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: calc(6em / 22);
  text-align: center;
  margin-bottom: 1em;
}