$breakpoints: (
  'xxs': 'screen and (max-width: 360px)',
  'xs': 'screen and (max-width: 480px)',
  'sm': 'screen and (max-width: 680px)',
  'md': 'screen and (max-width: 1024px)',
  'lg': 'screen and (max-width: 1200px)',
) !default;

$breakpoints-min: (
  'xs': 'screen and (min-width: 481px)',
  'sm': 'screen and (min-width: 681px)',
  'md': 'screen and (min-width: 1025px)',
  'lg': 'screen and (min-width: 1200px)',
  'xl': 'screen and (min-width: 1281px)',
  'xxl': 'screen and (min-width: 1400px)',
) !default;

$color-primary: #0080ED;

$color-text-primary: #000;
$color-text-secondary: #aaa;
$color-text-tertiary: #c0c0c0;
$color-text-white: #fff;

$color-bg-lightest: #fff;
$color-bg-light: #F5F5F5;
$color-bg-light-secondary: #F0F5FF;
$color-bg-red: #cba59f;
$color-bg-beige: #eee4d7;
$color-bg-dark: #363636;

$color-border: #808080;
$color-border-secondary: #c0c0c0;

$transition: all .4s;

$outer-width: 1200px;
$content-width: 1000px;


// 40, 48;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin mq-min($breakpoint-min: md) {
  @media #{map-get($breakpoints-min, $breakpoint-min)} {
    @content;
  }
}

@mixin make-paragraph ($size: 1.8rem, $line-height: 1.66, $spacing: 0.15em) {
  // font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  font-size: $size;
  font-weight:  bold;
  font-style: normal;
  font-stretch: normal;
  line-height: $line-height;
  letter-spacing: $spacing;
  text-align: left;
  color: $color-text-primary;
}

@mixin make-responsive-outer {
  width: $outer-width;
  margin: 0 auto;
  @include mq(lg) {
    width: $content-width;
    padding-right: 20px;
    padding-left: 20px;
  }
  @include mq(sm) {
    width: 100%;
  }
}

@mixin make-responsive-inner {
  width: calc(100% - 80px);
  margin: 0 auto;
  @include mq-min(lg) {
    width: $content-width;
  }
  @include mq(md) {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
  @include mq(sm) {
    // width: 100%;
    // padding-right: 0px;
    // padding-left: 0px;
  }
}

@mixin make-padding {
  padding-right: 24px;
  padding-left: 24px;
  @include mq(xxs) {
    padding-right: 2.4rem;
    padding-left: 2.4rem;
  }
}

@mixin make-gradation {
  background-image: rgb(0,128,237);
  background-image: linear-gradient(0deg, rgba(0,128,237,1) 0%, rgba(34,214,180,1) 100%);
  background-image: -moz-linear-gradient(0deg, rgba(0,128,237,1) 0%, rgba(34,214,180,1) 100%);
  background-image: -webkit-linear-gradient(0deg, rgba(0,128,237,1) 0%, rgba(34,214,180,1) 100%);
}
@mixin make-gradation2 {
  background-image: rgb(0,128,237);
  background-image: linear-gradient(0deg, rgba(0,128,237,1) 99%, rgba(34,214,180,1) 100%);
  background-image: -moz-linear-gradient(0deg, rgba(0,128,237,1) 99%, rgba(34,214,180,1) 100%);
  background-image: -webkit-linear-gradient(0deg, rgba(0,128,237,1) 99%, rgba(34,214,180,1) 100%);
}
