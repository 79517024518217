.p-security {
  &__pc, &__sp {
    width: 100%
  }

  &__pc {
    @include mq(sm) {
      display: none;
    }
  }

  &__sp {
    display: none;
    @include mq(sm) {
      display: block;
    }
  }
}