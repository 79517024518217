.c-leadText {
  font-size: 15px;
  font-weight: 500;
  line-height: 3;
  letter-spacing: calc(4em / 15);
  @include mq(sm) {
    line-height: 2.8;
  }
}

.c-verticalParagraph {
  font-size: 14px;
  line-height: 2.5;
  letter-spacing: calc( 2.15em / 14);
  writing-mode: vertical-rl;
  @include mq(sm) {
    writing-mode: unset;
    line-height: 2.7;
    letter-spacing: calc(0.8em / 14);
  }
}

.c-paragraph {
  font-size: 13px;
  line-height: 2.92;
  letter-spacing: calc(2em / 13);
  text-align: center;
  @include mq(sm) {
    line-height: 2.7;
    letter-spacing: calc(.8em / 14);
  }
}