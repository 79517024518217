/*==================================================
 * layout common
 *================================================*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html {
  overflow-y: scroll;
  font-size: 10px;
  @include mq(xxs) {
    font-size: calc(100vw / 32);
  }
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

input, textarea {
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th {
  text-align: left;
}

body {
  // font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", Verdana, "メイリオ", Meiryo, sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", Verdana, "メイリオ", Meiryo, sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  font-feature-settings: "palt" 1;
  // letter-spacing: 0.14em;
  color: $color-text-primary;
  @include mq(sm) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

b, strong, .bold{
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI semibold", "Helvetica Neue", HelveticaNeue, "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "Segoe UI", Verdana, "メイリオ", Meiryo, sans-serif;
}