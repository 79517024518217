.p-producer {
  background-image: url(../images/sec09/sec09_bg_pc.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 80px;

  @include mq(sm) {
    background-image: none;

    .c-heading {
      margin-bottom: 40px;
    }
  }

  &__name {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 30px;
    @include mq(sm) {
      margin-bottom: 20px;
    }
  }

  .c-subText {
    width: 43%;
    @include mq(sm) {
      width: 100%;
    }
  }

  &__img {
    width: 100%;
    @include mq-min(sm) {
      display: none;
    }
  }
}