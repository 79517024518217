.c-card, .c-card2 {
  background: $color-bg-lightest;
  border-radius: 5px;
  position: relative;
  box-shadow: 6px 3px rgbba(0,0,0,0.16);

  &__header {
    text-align: center;
    padding: 20px 20px 10px 20px;

    span {
      font-weight: bold;
    }
    span:first-child {
      font-size: 1.8rem;
      color: $color-primary;
      margin-right: 0.5em;
    }

    span:nth-child(2) {
      font-size: 3rem;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: bold;
  }

  img {
    width: 100%;
  }

  &__text {
    display: block;
    width: 100%;
    padding: 20px;
  }

  &__footer {
    padding: 0 20px 20px 20px;
    text-align: right;

    price {
      font-weight: bold;
    }
  }
}

.c-card2 {
  &__header {
    padding: 15px;
    background-color: $color-primary;
    color: white;
    -webkit-border-radius: 5px 5px 0px 0px / 5px 5px 0px 0px;
    -moz-border-radius: 5px 5px 0px 0px / 5px 5px 0px 0px;
    border-radius: 5px 5px 0px 0px / 5px 5px 0px 0px;
    > span:first-child {
      color: white;
    }
  }

  &__body {
    padding: 0 20px;
  }

  &__price {
    text-align: center;
    padding: 30px 0px;
    border-bottom: solid 1px $color-text-primary;
    font-size: 18px;
    @include mq(sm) {
      padding: 20px 0px;
    }
    
    > b {
      color: $color-primary;
      font-weight: bold;
    }

    p {
      font-weight: bold;
    }    

    strong {
      font-size: 30px;
    }
  }

  &__title {
    height: 80px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-weight: bold;
    }
  }

  &__text {
    padding: 0 0 20px 0;
  }
}

.c-cardList {
  display: flex;
  justify-content: space-between;
  @include mq(sm) {
    flex-direction: column;
  }

  > li {
    width: calc(33.333% - 10px);
    @include mq(sm) {
      width: 100%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
