.p-about {
  padding: 80px 0 60px;
  background-image: url(../images/sec02/sec02_bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  @include mq(md) {
    padding: 40px 0;
  }

  &__content__body {
    width: 56.5%;
    line-height: 1.8;
    margin-bottom: 50px;
    @include mq(md) {
      width: 100%;
      margin-bottom: 40px;
    }

    > p {
      @include make-paragraph;
    }
  }

  &__feature__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    @include mq(sm) {
      flex-direction: column;
    }

    > li {
      padding: 40px;
      width: calc(33.333% - 10px);
      margin-bottom: 17px;
      @include mq(md) {
        padding: 20px;
      }
      @include mq(sm) {
        width: 100%;
        padding: 40px;
      }

      img {
        height: 100px;
        margin-bottom: 30px;
      }

      p {
        @include make-paragraph;
        text-align: center;
      }
    }
  }


}