$z-sidemenu: 10;
$z-menubutton: 100;
$z-overlay: 5;

.l-mainWrapper {
  position: relative;
  padding-bottom: 90px;

  @include mq(sm) {
    padding-bottom: 135px;
  }

  @include mq(xxs) {
    padding-bottom: 153px;
  }


  // &::after {
  //   content: "";
  //   display: block;
  //   visibility: hidden;
  //   width: 100%;
  //   height: 100%;
  //   opacity: 0;
  //   transition: $transition;
  // }

  // &.is-on::after {
  //   visibility: visible;
  //   background-color: rgba(0,0,0,0.22);
  //   opacity: 1;
  //   transition: $transition;
  // }
}

.icon {
  height: 14px;
  transition: all .3s;
  width: 14px;
  vertical-align: middle;
}

.l-main {
  
}

.l-header--top + .l-main {
  padding-top: 0;
}

.l-footer {
  @include make-gradation;
  width: 100%;
  height: 60px;
  padding: 20px;
  text-align: center;

  &__copyright {
    color: $color-bg-lightest;
  }
}