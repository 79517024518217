.c-inner {
  @include make-responsive-inner;

  &__content {
    flex: 1;
    @include mq(sm) {
      @include make-padding;
    } 
  }


  &__imgWrapper {
    width: 330px;
    > img {
      max-width: 100%;
    }

    @include mq(sm) {
      width: 100%;
    }
  }
}

.c-section {
  padding: 60px 0;
  @include mq(md) {
    padding: 40px 0;
  }
}
